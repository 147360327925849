import React from "react";
import PropTypes from "prop-types";

const Aprojectaweek = props => {
  return <div></div>;
};

Aprojectaweek.propTypes = {};

export default Aprojectaweek;
